import type { Product, ProductFilters } from "./types";
import { getParamStringFromObject } from "~/utils/helpers";
import { getSession } from "~/services/session";
import { fetchAPI2 } from "../fetchAPI2";
import { simulateApiError } from "../errors";

export const retrieveProduct = async (
  sku: string,
  filters: ProductFilters = {}
): Promise<Product> => {
  let unauthenticated = true;
  const session = await getSession();
  if (session?.token) {
    unauthenticated = false;
  }

  const params = getParamStringFromObject(filters);

  // await simulateApiError({ path: `/products/${sku}${params}` });
  
  const data = await fetchAPI2<Product>(`/products/${sku}${params}`, {
    unauthenticated,
  });

  return data;
};
