import { query, createAsync } from "@solidjs/router";
import { _retrieveActivity } from "../roma-api/products";

export const getProductActivity = query(async (sku: string) => {
  return await _retrieveActivity(sku);
}, "product-activity");

export const useProductActivity = (
  sku: () => string,
  options: { deferStream: boolean } = { deferStream: false }
) => createAsync(() => getProductActivity(sku()), options);
