import { fetchAPI2 } from "../fetchAPI2";

type ActivityResponse = {
  CurrentViewers: number;
};

export const _retrieveActivity = async (sku: string) => {
  const data = await fetchAPI2<ActivityResponse>(`/products/${sku}/activity`, {
    unauthenticated: true,
  });

  return data;
};
